<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}设备配置</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="设备编码" prop="serialNumber">
				<el-input v-model.trim="ruleForm.serialNumber" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="选择设备" prop="smartDeviceModelId">
				<el-select
					v-model="ruleForm.smartDeviceModelId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'smartDeviceModelId')"
				>
					<el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="厂家">
				<el-input v-model.trim="ruleForm.manufacturer" disabled placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="使用参数设定">
				<div class="paramster" v-for="(item, index) in copyParamsterList" :key="index">
					<el-input placeholder="请输入时长" v-model.trim="item.usageTime" type="number" :disabled="isShowDetail">
						<template slot="append">分钟</template>
					</el-input>
					<span>/</span>
					<el-input placeholder="请输入价格" v-model.trim="item.price" type="number" class="priceClass" :disabled="isShowDetail">
						<template slot="append">元</template>
					</el-input>
					<i class="el-icon-circle-close" @click="delItem(index)" v-if="copyParamsterList.length > 1 && !isShowDetail"></i>
				</div>
				<p class="addParamster" @click="addParamster" v-if="!isShowDetail"><i class="el-icon-plus"></i> 添加参数</p>
			</el-form-item>
			<el-form-item label="设备使用" class="useDeviceClass" required>
				<el-form-item prop="provinceDictionaryItem">
					<el-select v-model="ruleForm.provinceDictionaryItem" placeholder="省" :disabled="isShowDetail" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityDictionaryItem">
					<el-select v-model="ruleForm.cityDictionaryItem" placeholder="市" :disabled="isShowDetail" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="districtCode">
					<el-select v-model="ruleForm.districtCode" placeholder="区县" :disabled="isShowDetail" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="ruleForm.townCode" placeholder="乡镇" :disabled="isShowDetail" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="ruleForm.regionCode" placeholder="村" :disabled="isShowDetail" @change="changeSelect($event, 'regionCode')">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
			</el-form-item>
			<el-form-item label="选择机构" prop="medicalOrganizationId">
				<el-select
					v-model="ruleForm.medicalOrganizationId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'medicalOrganizationId')"
				>
					<el-option v-for="item in institutionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				serialNumber: '',
				smartDeviceModelId: '',
				manufacturer: '',
				paramsterList: [],
				provinceDictionaryItem: '',
				cityDictionaryItem: '',
				districtCode: '',
				townCode: '',
				regionCode: '',
				medicalOrganizationId: '',
			},
			regionCode: '',
			deviceTypeList: [],
			economizeList: [],
			marketList: [],
			districtList: [],
			townList: [],
			villageList: [],
			institutionList: [],
			rules: {
				serialNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
				smartDeviceModelId: [{ required: true, message: '请选择', trigger: 'blur' }],
				paramsterList: [{ required: true, message: '请输入', trigger: 'blur' }],
				medicalOrganizationId: [{ required: true, message: '请选择', trigger: 'blur' }],
				provinceDictionaryItem: [{ required: true, message: '请选择', trigger: 'blur' }],
				cityDictionaryItem: [{ required: true, message: '请选择', trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
			copyParamsterList: [],
			searchMedicalLink: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.link = data.link;
			this.searchMedicalLink = data.searchMedical;
			this.getDeviceList();
			this.getEconomizeList();
			if (data.row) {
				setTimeout(() => {
					this.getDetail(this.link);
				}, 100);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.ruleForm.paramsterList = [];
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取设备列表
		getDeviceList() {
			this.$http
				.get(this.api['SmartDeviceModel#index'].href, { params: { size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.deviceTypeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			if (type == 'smartDeviceModelId') {
				this.ruleForm.manufacturer = this.deviceTypeList.find((item) => item.id == val)?.manufacturerDictionaryItem.title;
				return;
			}
			this.$forceUpdate();
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 获取机构列表
		getInstitList() {
			const { provinceDictionaryItem, cityDictionaryItem, districtCode, townCode, regionCode } = this.ruleForm;
			let code =
				provinceDictionaryItem && !cityDictionaryItem
					? provinceDictionaryItem
					: provinceDictionaryItem && cityDictionaryItem && !districtCode
					? cityDictionaryItem
					: provinceDictionaryItem && cityDictionaryItem && districtCode && !townCode
					? districtCode
					: provinceDictionaryItem && cityDictionaryItem && districtCode && townCode && !regionCode
					? townCode
					: regionCode;
			this.$http
				.get(this.searchMedicalLink, { params: { size: 9999, current: 1, regionCode: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.institutionList = res.data.collection || [];
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.regionCode = data.regionCode;
			this.ruleForm.manufacturer = this.deviceTypeList.find((item) => item.id == data.smartDeviceModelId)?.manufacturerDictionaryItem.title;
			let arr = data.smartDevicePricingDto.map((item) => {
				return {
					price: item.price / 100,
					usageTime: item.usageTime / 60,
					smartDeviceId: item.smartDeviceId,
				};
			});
			this.ruleForm.paramsterList = JSON.parse(JSON.stringify(arr));
			this.copyParamsterList = JSON.parse(JSON.stringify(arr));
			if (!data.regions && data.regions.length == 0) return;
			this.ruleForm.provinceDictionaryItem = (data.regions[0] && data.regions[0].code) || '';
			this.changeEconomize(data.regions[0].code, 1);
			this.ruleForm.cityDictionaryItem = (data.regions[1] && data.regions[1].code) || '';
			this.changeEconomize(data.regions[1].code, 2);
			this.ruleForm.districtCode = (data.regions[2] && data.regions[2].code) || '';
			this.changeEconomize(data.regions[2].code, 3);
			this.ruleForm.townCode = (data.regions[3] && data.regions[3].code) || '';
			this.changeEconomize(data.regions[3].code, 4);
			this.ruleForm.regionCode = (data.regions[4] && data.regions[4].code) || '';
		},
		changeEconomize(data, type) {
			this.getInstitList();
			if (!data || data == '') return;
			const { provinceDictionaryItem, cityDictionaryItem, districtCode, townCode, regionCode } = this.ruleForm;
			this.regionCode =
				provinceDictionaryItem && !cityDictionaryItem
					? provinceDictionaryItem
					: provinceDictionaryItem && cityDictionaryItem && !districtCode
					? cityDictionaryItem
					: provinceDictionaryItem && cityDictionaryItem && districtCode && !townCode
					? districtCode
					: provinceDictionaryItem && cityDictionaryItem && districtCode && townCode && !regionCode
					? townCode
					: regionCode;
			switch (type) {
				case 1:
					this.ruleForm.cityDictionaryItem = null;
					this.ruleForm.districtCode = null;
					this.ruleForm.townCode = null;
					this.ruleForm.regionCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									id: null,
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.ruleForm.districtCode = null;
					this.ruleForm.townCode = null;
					this.ruleForm.regionCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									id: null,
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.ruleForm.townCode = null;
					this.ruleForm.regionCode = null;
					this.townList = [];
					this.villageList = [];
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									id: null,
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.ruleForm.regionCode = null;
					this.villageList = [];
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									id: null,
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.paramsterList = [];
			this.copyParamsterList = [];
			this.$nextTick(() => {
				this.$refs[formName].resetFields();
			});
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						regionCode: !this.ruleForm.regionCode ? this.regionCode : this.ruleForm.regionCode,
						smartDevicePricingDtoList: this.copyParamsterList.map((item) => {
							return {
								price: item.price * 100,
								usageTime: item.usageTime * 60,
								smartDeviceId: item.smartDeviceId || null,
							};
						}),
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		//
		addParamster() {
			this.copyParamsterList.push({
				usageTime: '',
				price: '',
			});
			this.$forceUpdate();
		},
		//
		delItem(index) {
			this.copyParamsterList.splice(index, 1);
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .paramster {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #999999;
	position: relative;
	border: 1px solid #dcdfe6;
	border-radius: 4px;
	margin-bottom: 8px;
	.el-input {
		width: 200px !important;
		.el-input__inner {
			border: none;
			padding-right: 0;
		}
		.el-input-group__append {
			background-color: transparent;
			border: none;
		}
	}
	.priceClass {
		margin-right: 20px;
	}
	.el-icon-circle-close {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
	}
}
.addParamster {
	color: #1db9b1;
	cursor: pointer;
}
/deep/ .useDeviceClass {
	.el-form-item__content {
		display: flex;
		justify-content: space-between;
		.el-form-item {
			margin-right: 16px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
</style>